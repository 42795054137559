import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import KanbanImg from '../svg/Kanban';
import RevOpsHero from '../svg/RevOpsHero';
import TicketImg from '../svg/Ticket';
import revOpsTypes from '../data/revOpsTypes';
import GridCard from '../components/GridCard';

const RevOps = () => (
  <Layout>
    {/* Opening slide and RevopsHero */}
    <SplitSection
      id="about"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">RevOps</h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Automatically quantify and convert insights and opportunities into revenue.
          </p>
          <p className="mt-4 text-gray-600">Driving accountability and collaboration</p>
        </div>
      }
      secondarySlot={<RevOpsHero />}
    />

    {/* Kanban image page breaker */}
    <section id="services" className="py-40 lg:pb-20 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Collaborative opportunity management</h2>
      </div>
      <div className="container mx-auto py-20 w-4/5">
        <KanbanImg />
      </div>
    </section>

    {/* RevOps Info and Intro */}
    <SplitSection
      id="about"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Insight to ACTIONABLE opportunities
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            RevOps converts the insights traditionally found in your BI-tool into actionable
            opportunities.
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            The platform drives accountability and colaboration in achieving revenue opportunities.
          </p>
        </div>
      }
      secondarySlot={<TicketImg />}
    />

    {/* Three card explainer on Revops and its features */}
    <section id="services" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Core features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Live revenue opportunities</p>
              <p className="mt-4">
                RevOps allows for the immediate resolution of opportunities in the near or predicted
                future that would have otherwise likely been noticed a week in arrears.
              </p>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Accountabilty driving action</p>
              <p className="mt-4">
                Revenue opportunities auto-assigned to enabled individuals, provided with the
                information required to make change. The changes tracked and measured in terms of
                opportunity gained per party.
              </p>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Linked to business KPIs</p>
              <p className="mt-4">
                RevOps allows for tailored opportunity-type creation per individual or business unit
                that alligns with their KPIs or achievables for the quarter.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    {/* Examples of revops F and D flex */}
    <section id="more" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">
          Examples of RevOps in Formal retail and Distribution
        </LabelText>
        <div className="flex flex-col flex-wrap justify-center md:flex-row md:-mx-3">
          {revOpsTypes.map((gridItem) => (
            <div key={gridItem.Id} className="flex-initial px-3 ">
              <GridCard gridItem={gridItem} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Get me started</h3>
      <p className="mt-8 text-xl font-light">Drop us an email and we'll get back to you ASAP.</p>
      <p className="mt-8">
        <a href="mailto:info@cognizance.vision?subject=Request%20for%20information%20on%20Revops&body=I'd%20like%20to%20learn%20more%20about%20Cognizance's%20RevOps!%0A%0AI%20work%20for%3A%20%0A%0AI%20want%20to%20know...">
          <Button size="xl">Contact us</Button>
        </a>
      </p>
    </section>
  </Layout>
);

export default RevOps;
