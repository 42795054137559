export default [
  {
    Id: "e1",
    title: 'Items absent in store or de-ranged.',
  },
  {
    Id: "e2",
    title: 'Items with a low rate of sale',
  },
  {
    Id: "e3",
    title: 'Outliers from sales trends',
  },
  {
    Id: "e4",
    title: 'ROS ≠ restock frequency ',
  },
  {
    Id: "e5",
    title: 'Store late to promotion',
  },
  {
    Id: "e6",
    title: 'Ranged, no sales',
  },
  {
    Id: "e7",
    title: 'Out-Of-Stock',
  },
  {
    Id: "e8",
    title: 'KVI ranging exceptions',
  },
  {
    Id: "e9",
    title: 'Shift in cost per pallet',
  },
  {
    Id: "e10",
    title: 'High profit item in decline',
  },
  {
  Id: "e11",
  title: 'Danger stock levels',
},
];
